var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-lg text-dark" }, [_vm._v("Details")]),
      _vm.details.customization
        ? _c("div", { staticClass: "mt-4" }, [
            _vm.details.customization.nickname
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "text-grey text-xs" }, [
                      _vm._v("Card Name"),
                    ]),
                    _c("transition", { attrs: { mode: "out-in" } }, [
                      _c("p", { staticClass: "text-dark" }, [
                        _vm._v(
                          " " + _vm._s(_vm.details.customization.nickname) + " "
                        ),
                      ]),
                    ]),
                    _c("Hr"),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("p", { staticClass: "text-grey text-xs" }, [
            _vm._v("Card Number"),
          ]),
          _c(
            "transition",
            { attrs: { mode: "out-in" } },
            [
              _vm.details.pan
                ? _c(
                    "div",
                    { staticClass: "flex justify-between items-center" },
                    [
                      _c("p", { staticClass: "text-dark" }, [
                        _vm._v(_vm._s(_vm.spaceCardNumber(_vm.details.pan))),
                      ]),
                      _c("SecondaryButton", {
                        attrs: { text: "Copy", p: "py-1.5 px-4" },
                        on: {
                          click: function ($event) {
                            return _vm.copy(_vm.details.pan)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c("Skeleton", {
                    staticClass: "mt-2",
                    attrs: { height: "1rem", width: "50%" },
                  }),
            ],
            1
          ),
          _c("Hr"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("p", { staticClass: "text-grey text-xs" }, [_vm._v("CVV")]),
          _c(
            "transition",
            { attrs: { mode: "out-in" } },
            [
              _vm.details.cvv
                ? _c(
                    "div",
                    { staticClass: "flex justify-between items-center" },
                    [
                      _vm.details.cvv
                        ? _c("p", { staticClass: "text-dark" }, [
                            _vm._v(_vm._s(_vm.details.cvv)),
                          ])
                        : _vm._e(),
                      _c("SecondaryButton", {
                        attrs: { text: "Copy", p: "py-1.5 px-4" },
                        on: {
                          click: function ($event) {
                            return _vm.copy(_vm.details.cvv)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c("Skeleton", {
                    staticClass: "mt-2",
                    attrs: { height: "1rem", width: "50%" },
                  }),
            ],
            1
          ),
          _c("Hr"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("p", { staticClass: "text-grey text-xs" }, [
            _vm._v("Expiry Date"),
          ]),
          _c(
            "transition",
            { attrs: { mode: "out-in" } },
            [
              _vm.details.expirationMonth
                ? _c(
                    "div",
                    { staticClass: "flex justify-between items-center" },
                    [
                      _vm.details.expirationMonth
                        ? _c("p", { staticClass: "text-dark" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.details.expirationMonth) +
                                "/" +
                                _vm._s(_vm.details.expirationYear) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("SecondaryButton", {
                        attrs: { text: "Copy", p: "py-1.5 px-4" },
                        on: {
                          click: function ($event) {
                            return _vm.copy(
                              _vm.details.expirationMonth +
                                "/" +
                                _vm.details.expirationYear
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c("Skeleton", {
                    staticClass: "mt-2",
                    attrs: { height: "1rem", width: "50%" },
                  }),
            ],
            1
          ),
          _c("Hr"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("p", { staticClass: "text-grey text-xs" }, [_vm._v("Type")]),
          _c(
            "transition",
            { attrs: { mode: "out-in" } },
            [
              _vm.details.type
                ? _c("p", { staticClass: "text-dark" }, [
                    _vm._v(
                      " " + _vm._s(_vm._f("capitalize")(_vm.details.type)) + " "
                    ),
                  ])
                : _c("Skeleton", {
                    staticClass: "mt-2",
                    attrs: { height: "1rem", width: "30%" },
                  }),
            ],
            1
          ),
          _vm.details.type === "VIRTUAL" ? _c("Hr") : _vm._e(),
        ],
        1
      ),
      _vm.details.type === "VIRTUAL"
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("p", { staticClass: "text-grey text-xs" }, [
                _vm._v("Payment plan"),
              ]),
              _c(
                "transition",
                { attrs: { mode: "out-in" } },
                [
                  _vm.loading
                    ? _c("Skeleton", {
                        staticClass: "mt-2",
                        attrs: { height: "1rem", width: "30%" },
                      })
                    : _c(
                        "div",
                        { staticClass: "flex justify-between items-center" },
                        [
                          _c("div", [
                            _vm.newPaymentPlan.name
                              ? _c("p", { staticClass: "text-dark" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.newPaymentPlan.name) + " "
                                  ),
                                ])
                              : _c("p", { staticClass: "text-dark" }, [
                                  _vm._v("No payment plan set"),
                                ]),
                          ]),
                          _c("SecondaryButton", {
                            attrs: { text: "Change" },
                            on: {
                              click: function ($event) {
                                _vm.showPaymentStyle = true
                              },
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("PaymentPlan", {
        attrs: {
          schedules: _vm.schedules,
          display: _vm.showPaymentStyle,
          type: _vm.paymentType,
          loading: _vm.selectLoading,
        },
        on: {
          flexiUpdated: _vm.setFlexiConfig,
          close: _vm.closePaymentStyle,
          style: _vm.chooseStyle,
          selected: _vm.planSelected,
        },
      }),
      _c("AddDebitCard", {
        attrs: { display: _vm.showAddDebitCard },
        on: { close: _vm.handleAddDebitCardClose },
      }),
      _c(
        "LargeModal",
        {
          attrs: { display: _vm.showCreditCardCalculator },
          on: {
            close: function ($event) {
              _vm.showCreditCardCalculator = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "lg:w-9/12 mx-auto" },
            [_c("CreditCardCalculator")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }