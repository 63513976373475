<template>
  <div>
    <!-- <div class="spam-notice bg-white px-0 pt-0 rounded-lg">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
          fill="#0EA2F5" />
      </svg>
      <p class="text-sm text-dark text-left">
        There's a 5% charge for virtual card transactions when you split, spread
        or pay in instalment. <span class="text-brandPurple cursor-pointer" @click="showCreditCardCalculator = true">Use
          calculator</span>
      </p>
    </div>
    <Hr /> -->
    <p class="text-lg text-dark">Details</p>

    <div v-if="details.customization" class="mt-4">
      <div v-if="details.customization.nickname">
        <p class="text-grey text-xs">Card Name</p>
        <transition mode="out-in">
          <p class="text-dark">
            {{ details.customization.nickname }}
          </p>
        </transition>
        <Hr />
      </div>
    </div>
    <div class="mt-4">
      <p class="text-grey text-xs">Card Number</p>
      <transition mode="out-in">
        <div v-if="details.pan" class="flex justify-between items-center">
          <p class="text-dark">{{ spaceCardNumber(details.pan) }}</p>
          <SecondaryButton text="Copy" @click="copy(details.pan)" p="py-1.5 px-4" />
        </div>
        <Skeleton v-else height="1rem" width="50%" class="mt-2" />
      </transition>
      <Hr />
    </div>
    <div class="mt-4">
      <p class="text-grey text-xs">CVV</p>
      <transition mode="out-in">
        <div v-if="details.cvv" class="flex justify-between items-center">
          <p v-if="details.cvv" class="text-dark">{{ details.cvv }}</p>
          <SecondaryButton text="Copy" @click="copy(details.cvv)" p="py-1.5 px-4" />
        </div>
        <Skeleton v-else height="1rem" width="50%" class="mt-2" />
      </transition>
      <Hr />
    </div>
    <div class="mt-4">
      <p class="text-grey text-xs">Expiry Date</p>
      <transition mode="out-in">
        <div v-if="details.expirationMonth" class="flex justify-between items-center">
          <p v-if="details.expirationMonth" class="text-dark">
            {{ details.expirationMonth }}/{{ details.expirationYear }}
          </p>
          <SecondaryButton text="Copy" @click="copy(details.expirationMonth + '/' + details.expirationYear)"
            p="py-1.5 px-4" />
        </div>
        <Skeleton v-else height="1rem" width="50%" class="mt-2" />
      </transition>
      <Hr />
    </div>
    <div class="mt-4">
      <p class="text-grey text-xs">Type</p>
      <transition mode="out-in">
        <p v-if="details.type" class="text-dark">
          {{ details.type | capitalize }}
        </p>
        <Skeleton v-else height="1rem" width="30%" class="mt-2" />
      </transition>
      <Hr v-if="details.type === 'VIRTUAL'" />
    </div>
    <div class="mt-4" v-if="details.type === 'VIRTUAL'">
      <p class="text-grey text-xs">Payment plan</p>
      <transition mode="out-in">
        <Skeleton v-if="loading" height="1rem" width="30%" class="mt-2" />
        <div v-else class="flex justify-between items-center">
          <div>
            <p class="text-dark" v-if="newPaymentPlan.name">
              {{ newPaymentPlan.name }}
            </p>
            <p class="text-dark" v-else>No payment plan set</p>
          </div>
          <SecondaryButton text="Change" @click="showPaymentStyle = true" />
        </div>
      </transition>
    </div>
    <PaymentPlan :schedules="schedules" @flexiUpdated="setFlexiConfig" :display="showPaymentStyle"
      @close="closePaymentStyle" @style="chooseStyle" @selected="planSelected" :type="paymentType"
      :loading="selectLoading" />
    <AddDebitCard :display="showAddDebitCard" @close="handleAddDebitCardClose" />
    <LargeModal :display="showCreditCardCalculator" @close="showCreditCardCalculator = false">
      <div class="lg:w-9/12 mx-auto">
        <CreditCardCalculator />
      </div>
    </LargeModal>
  </div>
</template>
<script>
import Hr from "@/UI/Hr";
import { SecondaryButton } from "@/UI/Button";
import PaymentPlan from "../PaymentPlan";
import { mapActions } from "vuex";
import copy from "@/mixins/copy";
import { LargeModal } from '@/UI/Modals';
import zillaCard from "@/mixins/zillaCard";

export default {
  mixins: [copy, zillaCard],
  components: {
    Hr,
    SecondaryButton,
    PaymentPlan,
    AddDebitCard: () => import("../AddDebitCard.vue"),
    CreditCardCalculator: () => import('../Calculator/Calculator.vue'),
    LargeModal
  },
  data() {
    return {
      showPaymentStyle: false,
      paymentType: {},
      loading: false,
      selectLoading: false,
      newPaymentPlan: {},
      showAddDebitCard: false,
      showCreditCardCalculator: false,
      config: {
        numberOfRepayment: 1,
        upfrontAmountPercentage: 25
      },
      schedules: []
    };
  },
  props: {
    details: {
      type: Object,
      default: () => { },
      required: true,
    },
  },

  mounted() {
    this.getCurrentPaymentPlan();
    this.getPaymentPlans();
  },

  methods: {
    ...mapActions("card", [
      "getDefaultPaymentPlan",
      "updateCardDefaultPaymentPlan",
    ]),
    ...mapActions("notification", ["showAlert"]),
    ...mapActions("api", ["setErrorCodeToWatch"]),
    chooseStyle(e) {
      this.paymentType = e;
    },

    setDefaultPaymentPlan() {
      this.setErrorCodeToWatch(412);
      this.selectLoading = true;
      this.updateCardDefaultPaymentPlan({
        issuedCardId: this.details.id,
        paymentPlanId: this.paymentType.id,
      })
        .then((res) => {
          this.newPaymentPlan = res?.data?.data?.paymentPlan;
          this.showPaymentStyle = false;
          this.selectLoading = false;
          // this.schedules = [];
          this.showAlert({
            display: true,
            type: "green-success",
            title: "Payment plan changed successfully ",
            description: `Your default payment plan for this card has been changed to"${this.newPaymentPlan.name}" payment.`,
            buttonText: "Okay"
          });
        })
        .catch((err) => {
          this.selectLoading = false;
          if (err.response.status === 412) {
            this.showAddDebitCard = true;
            this.showPaymentStyle = false;
          }
        });
    },


    setFlexiblePlan() {
      this.config.upfrontAmountPercentage = Number(this.config.upfrontAmountPercentage);
      this.selectLoading = true;
      this.updateCardDefaultPaymentPlan({
        issuedCardId: this.details.id,
        flexiblePaymentPlanConfig: this.config
      })
        .then((res) => {
          this.newPaymentPlan = res?.data?.data?.paymentPlan;
          this.showPaymentStyle = false;
          this.selectLoading = false;
          this.showAlert({
            display: true,
            type: "green-success",
            title: "Payment plan changed successfully ",
            description: `Your default payment plan for this card has been changed to "${this.newPaymentPlan.name}".`,
            buttonText: "Okay"
          });
        })
        .catch((err) => {
          this.selectLoading = false;
          if (err.response.status === 412) {
            this.showAddDebitCard = true;
            this.showPaymentStyle = false;
          }
        });
    },

    planSelected() {
      if (this.checkIfDefaultPlan(this.paymentType.id)) {
        this.setDefaultPaymentPlan();
      }
      else {
        this.setFlexiblePlan();
      }
    },

    handleAddDebitCardClose() {
      this.showAddDebitCard = false;
      this.showPaymentStyle = true;
    },

    getCurrentPaymentPlan() {
      this.loading = true;
      this.getDefaultPaymentPlan(this.$route.params.id)
        .then((res) => {
          let data = res?.data?.data?.paymentPlan;
          // Check if default plan of flexi
          if (data) {
            this.paymentType = data;
            this.newPaymentPlan = this.paymentType;
          }
          this.schedules = data?.paymentPlan.schedules;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    spaceCardNumber(number) {
      let card = number.match(/.{1,4}/g);
      return card.join(" ");
    },


    closePaymentStyle() {
      this.showPaymentStyle = false;
      this.paymentType = this.newPaymentPlan;
    },

    setFlexiConfig(config) {
      this.config = config;
    }
  },
};
</script>
